import moment from 'moment';
let today = moment();
moment.locale('en');
let startD = moment().add(5, 'days');
let exitD = moment().add(7, 'days');

export default {
  hotels: [],
  filtered: [],
  visited: [],
  favs: [],
  filters: [
    { msg: '' },
    { name: '' },
    { stars: [] },
    { rating: -1 },
    { facilities: [] },
    { price: [0, 0], priceSlider: [0, 0] },
    { type: [] },
    { offer: false, hasOffers: 0 }
  ],
  searchInfo: {
    city: '',
    lat: 0,
    lng: 0,
    start: startD,
    end: exitD,
    rooms: [[1, 0, []]],
    rad: 25,
    searchLang: 'en',
    searchUrl: '/'
  },
  radius: 25,
  subStatus: 'start',
  gMapLoaded: false,
  searchTime: today,
  routes: 'none',
  modal: 'none',
  page: 1,
  msg: null,
  error: false,
  loading: 'none',
  order: ['relevance', false],
  hotelsMap: false,
  citiesList: [0],
  mapInfo: {},
  mapInfoHover: null,
  start: startD,
  end: exitD,
  rooms: [[1, 0]],
  guests: [],
  guestsContact: {},
  valid: {},
  complete: false,
  showBar: '',
  activeBar: 'no',
  showRoom: false,
  activeRoom: '0',
  showModalCal: false,
  toggleCal: false,
  filterModal: false,
  filterMap: false,
  lat: 0,
  lng: 0,
  city: '',
  cityEn: '',
  cityLocal: '',
  cityHome: false,
  userInfo: { browser: null, ipPrivate: null, ipPublic: null, facebook: null },
  cookies: false,
  hotelInfo: {},
  exchangeRate: { EUR: 1, USD: 1 },
  priceValidation: {},
  storeReservation: null,
  bookInfo: [],
  packSelected: {},
  hotelSelected: {},
  hotelsHome: [],
  completed: null,
  redirectUrl: null,
  godMode: false
};
